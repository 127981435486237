// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import AllProductsHeader from '../components/allProducts/AllProductsHeader';
import AllProductsGrid from '../components/allProducts/AllProductsGrid';
import { SettingsContext } from '../contexts';
import { useGlobalComponentsQuery, useLocale } from '../hooks/index';
import SEO from '../components/shared/SEO';
import AllProductsFilter from '../components/allProducts/AllProductsFilter';

export interface allProductsProps {
  data: {
    allContentfulProduct: any;
    contentfulPageAllProducts: {
      seoTitle: string;
      seoDescription: string;
      videoThumbnailAtNumber3: string;
      contentThumbnailAtNumber3: string;
    };
  };
  pageContext: any;
}

const AllProducts = ({
  data: {
    allContentfulProduct,
    contentfulPageAllProducts: {
      seoTitle,
      seoDescription,
      videoThumbnailAtNumber3,
      contentThumbnailAtNumber3,
    },
  },
  pageContext,
}: allProductsProps) => {
  const {
    categoryFilter,
    roomFilter,
    collectionFilter,
    colorFilter,
    priceFilter,
    productAvailabelityFilter,
    allProductShopify,
    productSorting,
    resetFilters,
  }: {
    categoryFilter: any;
    roomFilter: any;
    colorFilter: any;
    collectionFilter: any;
    productSorting: any;
    allProductShopify: any;
    productAvailabelityFilter: any;
    resetFilters: () => void;
  } = useContext(SettingsContext);

  const locale = useLocale();

  // When someone changes locale, reset filter
  useEffect(() => {
    // console.log("LOCALE CHANGED");
    // resetFilters()
  }, [locale]);

  const global = useGlobalComponentsQuery();

  const [allProducts] = useState(pageContext.allProducts);
  const [products, setProducts] = useState(pageContext.allProducts);
  const { productCategory } = pageContext;

  let productNew = allContentfulProduct?.nodes.filter(item => item.node_locale === locale);

  function sortingProduct(arr) {
    if (productSorting == 'NEWEST') {
      const sortingByTagNew = arr.sort((a, b) => {
        return a.productTag.localeCompare(b.productTag, undefined, { sensitivity: 'base' });
      });

      setProducts(sortingByTagNew);
    } else if (productSorting == 'Ascending (A - Z)') {
      const sortedArr = arr.sort((a, b) => {
        return a.titleToShow.localeCompare(b.titleToShow, undefined, { sensitivity: 'base' });
      });
      setProducts(sortedArr);
    } else if (productSorting == 'Descending (Z - A)') {
      const sortedArr = arr.sort((a, b) => {
        return b.titleToShow.localeCompare(a.titleToShow, undefined, { sensitivity: 'base' });
      });
      setProducts(sortedArr);
    } else {
      setProducts(arr);
    }
  }

  const filterByPrice = array => {
    const filteredProdsByPrice = array.filter(product => {
      if (product.shopifyData.variants[0].price < priceFilter) {
        return product;
      }
      sortingProduct(filteredProdsByPrice);
    });
    sortingProduct(array);
  };

  const filterByRoom = array => {
    if (roomFilter.length > 0) {
      const filteredProdsByRoom = array.filter(product => {
        const roomsPerProduct = product.room
          ? Object.values(product.room).find(mat => roomFilter.includes(mat))
          : false;
        if (roomsPerProduct) return product;
      });
      return filterByPrice(filteredProdsByRoom);
    }
    return filterByPrice(array);
  };

  const filterByColor = array => {
    if (colorFilter.length > 0) {
      const filteredProdsByColor = array.filter(product => colorFilter.includes(product.color));
      return filterByRoom(filteredProdsByColor);
    }
    return filterByRoom(array);
  };

  const filterByStock = array => {
    if (
      productAvailabelityFilter.length > 0 &&
      (productAvailabelityFilter[0] === 'In Stock' || productAvailabelityFilter[0] === 'Voorraad')
    ) {
      const filteredProdsByStock = array?.filter(item => {
        const checkProduct = allProductShopify?.filter(a => a.node.handle === item.slug);
        if (checkProduct.length > 0) {
          const checkAvailable = checkProduct[0].node.variants.edges.filter(
            b => b.node.quantityAvailable > 0
          );
          if (checkAvailable.length > 0) {
            return item;
          }
        }
      });
      return filterByColor(filteredProdsByStock);
    }
    if (
      productAvailabelityFilter.length > 0 &&
      (productAvailabelityFilter[0] === 'Pre Order' ||
        productAvailabelityFilter[0] === 'Voorafgaande Bestelling')
    ) {
      const filteredProdsByStock = array?.filter(item => {
        const checkProduct = allProductShopify?.filter(a => a.node.handle === item.slug);
        if (checkProduct.length > 0) {
          const checkAvailable = checkProduct[0].node.variants.edges.filter(
            b => b.node.quantityAvailable > 0
          );
          if (checkAvailable.length === 0) {
            return item;
          }
        }
      });
      return filterByColor(filteredProdsByStock);
    }
    return filterByColor(array);
  };

  const filterByCollection = array => {
    // if (collectionFilter.length > 0) {
    //   const filteredProdsByCollection = array.filter(product =>
    //     collectionFilter.includes(product.collection)
    //   );
    //   return filterByStock(filteredProdsByCollection);
    // }
    return filterByStock(array);
  };

  const filterByCategory = array => {
    if (window.location.href.split('/')[4] === 'newest') {
      filterByCollection(productNew);
    } else {
      if (categoryFilter.length > 0) {
        const filteredProdsByCategory = array.filter(product =>
          categoryFilter.includes(product.productCategory.category)
        );
        return filterByCollection(filteredProdsByCategory);
      }
      return filterByCollection(array);
    }
  };

  useEffect(() => {
    const prod = allProducts;
    filterByCategory(prod);
  }, [
    categoryFilter,
    roomFilter,
    collectionFilter,
    colorFilter,
    priceFilter,
    productCategory,
    productAvailabelityFilter,
    productSorting,
  ]);

  return (
    <>
      <SEO title={seoTitle} description={seoDescription} image={global?.seoImage.file.url} />
      <AllProductsGrid
        items={products}
        allProductsCategories={pageContext.allProductsCategories}
        pageProductCategory={productCategory}
        videoThumbnailAtNumber3={videoThumbnailAtNumber3}
        contentThumbnailAtNumber3={contentThumbnailAtNumber3}
      />
      <AllProductsFilter
        allProductsRooms={pageContext.allProductsRooms}
        allProductsCollections={pageContext.allProductsCollections}
        allProductsColors={pageContext.allProductsColors}
      />
    </>
  );
};

export default AllProducts;

export const AllProductsPageQuery = graphql`
  query AllProductsPage($id: String!) {
    allContentfulProduct(filter: { productTag: { in: ["New", "Nieuw"] } }) {
      nodes {
        node_locale
        title
        titleToShow
        slug
        artist
        material
        productCategory {
          category
        }
        shopifyData {
          variants {
            compare_at_price
            inventory_quantity
            price
            title
            sku
            id
            product_id
          }
        }
        productTag
        thumbnailImages {
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    contentfulPageAllProducts(id: { eq: $id }) {
      seoTitle
      seoDescription
      videoThumbnailAtNumber3
      contentThumbnailAtNumber3
    }
  }
`;
